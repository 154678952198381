@import "variables/_variables";
// Set the brand colours on wp-login
$login-color: $button-orange;
.login {
  background: white;
  /* Set the size of the login logo */
  h1 a {
    background-image: url("../img/logo-login.png");
    background-size: 250px;
    width: 320px;
    height: 200px;
  }
  /* Hide the "Back to (website)" link */
  p#backtoblog {
    display: none;
  }
  /* Hide the "Log in" link on the last password page, as it's pointless*/
  &.login-action-lostpassword p#nav {
    display: none;
  }
  /* Center align the Lost Password link */
  &.login-action-login p#nav {
    text-align: center;
  }
  input#wp-submit {
    background-color: $login-color;
    border-color: $login-color;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: darken($login-color, 10%);
      border-color: darken($login-color, 10%);
    }
  }
  .message {
    border-left-color: $login-color;
  }
  form {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
  }
}

.wp-admin {
  #wp-auth-check-wrap #wp-auth-check {
    background-color: $login-color;
  }
}

.wp-core-ui .button-primary {
  text-shadow: none;
}
