/***************************
Global
***************************/
$container: 1920px; // Jason has requested this.
$brand: #000;
$text-color: #1a1a1a;
$link-color: #006541;
$link-hover-color: #80ba26;
$button-orange: #c56c0b;
$button-hover-orange: #ff8c10;
$border: #4b5a3c;
$text-light: #767676;
$input-border: #949494;
$border-grey: #949494;
$brand-beige: #f1e5c0;
$brand-brown: #553112;
$brand-orange: #c56c0b;
$brand-green: #24431e;
$brand-alt-green: #63901d;
$brand-light-green: #b2c752;
$brand-lightest-green: #c8c5a0;
$brand-menu-green: #b2c752;

$container-color: $brand-beige;
$footer-top-bg: $brand-brown;
$footer-bg: $brand-green;

/***************************
Subject & Course Type Colours
***************************/

$subject-colors: (
    agriculture: #9d8258,
    floristry: #4d8a4b,
    motor-vehicle: #39475a,
    business-studies: #9392a4,
    science: #7994a8,
    animal-management: #847b5f,
    early-years: #7bb0e0,
    horticulture: #0d835b,
    sports-studies: #d9a700,
    foundation-studies: #f37971,
    performing-arts: #463b6e,
    production-arts: #7d5585,
    outdoor-education: #d18101,
    equine-studies: #a47056,
    media-production: #614c9a,
    sport-academies: #006944,
    art-design: #9e1981,
    health-social-care: #7eb1c1,
    public-services: #d6a554,
    alfie: #e27e91,
    beekeeping: #e29a17,
    countryside: #927855,
    royal-horticultural-society: #128762,
    apprenticeships: #ec6707,
    higher-education: #3bb9b2,
    full-time: $brand-green,
    part-time: $brand-green,
    land-based-training: $brand-green,
    careers: #006541,
    equine: #a47056,
    animal-behaviour-welfare: #3bb9b2,
    ecology-conservation: #3bb9b2,
    veterinary-physiotherapy: #3bb9b2,
    arboriculture: #9db214,
    childrens-development: #3bb9b2
);

$land-based-training: $brand-green;

/***************************
Fonts
***************************/
$sans-serif: "Montserrat", "Arial", sans-serif;
$serif: Georgia, "Times New Roman", Times, serif;
$monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

$standard-heading: "ff-prater-sans-web", sans-serif;
$alternate-heading: "IntroRustG", sans-serif;

$base-font: $sans-serif;
$base-font-size: 18px;
$base-line-height: 1.4;

/***************************
Breakpoints
***************************/
$bp-sm: 480px;
$bp-sm-max: ($bp-sm - 1);
$bp-md: 768px;
$bp-md-max: ($bp-md - 1);
$bp-lg: 1024px;
$bp-lg-max: ($bp-lg - 1);
$bp-xlg: 1200px;
$bp-xlg-max: ($bp-xlg - 1);

/*****************************
Brand Colours
*****************************/
$twitter: #55acee;
$facebook: #3b5998;
$linkedin: #0976b4;
$googleplus: #dd4b39;
$youtube: #e52d27;
$instagram: radial-gradient(
            circle farthest-corner at 28% 100%,
            #fcdf8f 0%,
            #fbd377 10%,
            #fa8e37 22%,
            #f73344 35%,
            rgba(247, 51, 68, 0) 65%
        )
        no-repeat,
    linear-gradient(145deg, #3051f1 10%, #c92bb7 70%) no-repeat;

$kontainer-padding-mobile: 20px;
$kontainer-padding-tablet: 30px;
$kontainer-padding-desktop: 50px;
